
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
      <div class="container">
        <a class="navbar-brand" href="/"><img src="{{whitelabel.logo}}" alt="{{whitelabel.title}}" class="pull-left"></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive"
                aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarResponsive">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <a class="nav-link" href="/" routerLink="/">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/logout" routerLink="/logout"><i class="fas fa-door-open"></i> Log-out</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div class="content-wrapper">
      <div class="container">
        <h2 class="mb-3">{{greeting}}</h2>

        <alert></alert>

        <div *ngIf="event">
          <h3 *ngIf="event.status!='CANCELED'">You got an appointment</h3>
          <h3 *ngIf="event.status=='CANCELED'">You had an appointment</h3>
          <h4>{{event.start | date : "medium"}}</h4>
          <span class="badge" [ngClass]="{'badge-success':event.status=='CONFIRMED', 'badge-warning':event.status!='CONFIRMED'}">{{event.status}}</span>

          <p class="mt-sm-3">
            <span *ngIf="event.status!='CANCELED'">Your appointment is for </span>
            <span *ngIf="event.status=='CANCELED'">Your canceled appointment was for </span>
            <b>{{event.appointment_type.name}}</b><br/>
            <em>{{event.appointment_type.description}}</em>
          </p>

          <p *ngIf="event.status=='CANCELED'">
            To make a new appointment, <a href="/" routerLink="/"><b>click here</b></a>.
          </p>

          <p>
            <button type="button" *ngIf="event.allowed_operations.indexOf('confirm') >= 0"
                    class="mr-1 btn btn-info"
                    (click)="confirmAppointment()">Confirm this appointment
            </button>
            <button type="button" *ngIf="event.allowed_operations.indexOf('cancel') >= 0"
                    class="btn btn-danger" (click)="cancelAppointment()">Cancel this appointment
            </button>
            <span class="btn-group" *ngIf="event.status!='CANCELED'">
          <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Add to Calendar
          </button>
          <ul class="dropdown-menu" >
            <li><a [href]="event.google" target="_blank">Google Calendar</a></li>
            <li><a [href]="event.ics" download>iCal</a></li>
            <li><a [href]="event.ics" download>Outlook</a></li>
            <li><a [href]="event.yahoo" target="_blank">Yahoo! Calendar</a></li>
          </ul>
        </span>
          </p>

        </div>

        <div class="row" *ngIf="whitelabel.page_footer" >
          <div class="col-12 mt-lg" [innerHTML]="page_footer()"></div>
        </div>

      </div>
    </div>

  