/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../_directives/alert.component.ngfactory";
import * as i2 from "../_directives/alert.component";
import * as i3 from "../_services/alert.service";
import * as i4 from "@angular/common";
import * as i5 from "./logout.component";
import * as i6 from "@angular/router";
import * as i7 from "../_providers/global-settings";
var styles_LogoutComponent = [];
var RenderType_LogoutComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LogoutComponent, data: {} });
export { RenderType_LogoutComponent as RenderType_LogoutComponent };
function View_LogoutComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "modal"], ["id", "loginModal"], ["style", "display:block; top:5%; position:absolute;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "modal-dialog"], ["role", "document"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "text-center"], ["style", "margin-bottom:20px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "img", [["style", "max-height:200px; max-width:100%;"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "alert", [], null, null, null, i1.View_AlertComponent_0, i1.RenderType_AlertComponent)), i0.ɵdid(7, 180224, null, 0, i2.AlertComponent, [i3.AlertService], null, null)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.whitelabel.logo, ""); var currVal_1 = i0.ɵinlineInterpolate(1, "", _co.whitelabel.title, ""); _ck(_v, 5, 0, currVal_0, currVal_1); }); }
export function View_LogoutComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_LogoutComponent_1)), i0.ɵdid(1, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.whitelabel && _co.whitelabel.id); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LogoutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_LogoutComponent_0, RenderType_LogoutComponent)), i0.ɵdid(1, 114688, null, 0, i5.LogoutComponent, [i6.Router, i7.GlobalSettings, i3.AlertService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LogoutComponentNgFactory = i0.ɵccf("app-root", i5.LogoutComponent, View_LogoutComponent_Host_0, {}, {}, []);
export { LogoutComponentNgFactory as LogoutComponentNgFactory };
