import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Rx';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {SafeResourceUrl} from '@angular/platform-browser';
import {map} from 'rxjs/operators';

export interface AppointmentType {
  duration: number,
  created_at: string,
  description: string,
  id: string,
  name: string
}

export interface Settings {
  appointment_types: AppointmentType[],
  max_search_results: number,
  type_label: string,
  page_footer: string | null,
  title: string,
  allow_consultant_selection: boolean,
  client_bookings_limit: number,
  mfaid: string,
  custom_url: string,
  default_originator: string,
  intro_body: string,
  default_search_days_offset: number,
  user: string,
  show_end_time: boolean,
  consultant_label: string,
  logo: string,
  allow_registrations: boolean,
  id: string,
  css: string | SafeResourceUrl,
  confirmation_body: string,
  client?: string,
  ask_home_address_label: boolean,
  ask_email: boolean
}


@Injectable()
export class SettingsResource {
  constructor(private http: HttpClient) {
  }

  get(): Observable<Settings> {
    return this.http.get(`${environment.tasks_endpoint}/api/appointments/public/settings.json`)
      .pipe(map(r => {
        return r as Settings
      }));
  }
}

@Injectable()
export class CalendarsResource {
  constructor(private http: HttpClient) {
  }

  get(): Observable<any> {
    return this.http.get(`${environment.tasks_endpoint}/api/appointments/public/calendars.json`);
  }
}

@Injectable()
export class ClientResource {
  constructor(private http: HttpClient) {
  }

  exists(lookup: string): Observable<any> {
    return this.http.post(
      `${environment.tasks_endpoint}/api/appointments/public/client_exists/`,
      {lookup: lookup});
  }

  get_data(data: any): Observable<any> {
    return this.http.post(`${environment.tasks_endpoint}/api/appointments/public/get_client_data/`, data);
  }

  get_events(from: string, to: string): Observable<any> {
    const client = sessionStorage.getItem('currentAppointmentUser');
    return this.http.get(
      `${environment.tasks_endpoint}/api/appointments/public/get_client_events/?client=${client}&from=${from}&to=${to}`);
  }

  create(data: any): Observable<any> {
    return this.http.post(
      `${environment.tasks_endpoint}/api/appointments/public/create_client/`,
      data);
  }

  addAddress(data: any): Observable<any> {
    return this.http.post(
      `${environment.tasks_endpoint}/api/appointments/public/address/save/`,
      data);
  }

  removeAddress(data: any): Observable<any> {
    return this.http.post(
      `${environment.tasks_endpoint}/api/appointments/public/address/remove/`,
      data);
  }
}

@Injectable()
export class AddressResource {
  constructor(private http: HttpClient) {
  }

  validate(data: any): Observable<any> {
    return this.http.post(`${environment.tasks_endpoint}/api/appointments/public/address/validate/`, data);
  }
}

@Injectable()
export class AppointmentResource {
  constructor(private http: HttpClient) {
  }

  get_timeslots(type: string, from: string, calendar: any, address_id?: string): Observable<any> {
    const client = sessionStorage.getItem('currentAppointmentUser');
    let url = `${environment.tasks_endpoint}/api/appointments/public/timeslots/?`;
    if (calendar) {
      url += `&calendar=${calendar}`.replace(/,$/g, '');
    }
    if (type) {
      url += `&type=${type}`.replace(/,$/g, '');
    }
    if (from) {
      url += `&from=${from}`;
    }
    if (client) {
      url += `&client=${client}`;
    }
    if (address_id) {
      url += `&address_id=${address_id}`;
    }
    return this.http.get(url);
  }

  book(timeslot: any, email?: string): Observable<any> {
    const client = sessionStorage.getItem('currentAppointmentUser');
    return this.http.post(
      `${environment.tasks_endpoint}/api/appointments/public/book/`,
      {timeslot: timeslot, client: client, email: email});
  }

  get(calendar: string, event: string): Observable<any> {
    const client = sessionStorage.getItem('currentAppointmentUser');
    return this.http.get(
      `${environment.tasks_endpoint}/api/appointments/public/event/${client}/${calendar}/${event}/get/`);
  }

  confirm(calendar: string, event: string): Observable<any> {
    const client = sessionStorage.getItem('currentAppointmentUser');
    return this.http.get(
      `${environment.tasks_endpoint}/api/appointments/public/event/${client}/${calendar}/${event}/confirm/`);
  }

  cancel(calendar: string, event: string): Observable<any> {
    const client = sessionStorage.getItem('currentAppointmentUser');
    return this.http.get(
      `${environment.tasks_endpoint}/api/appointments/public/event/${client}/${calendar}/${event}/cancel/`);
  }
}

