import {Component, OnInit} from '@angular/core';
import {GlobalSettings} from '../_providers';
import {ActivatedRoute} from '@angular/router';
import {AlertService, AppointmentResource, AppointmentType, CalendarsResource, ClientResource} from '../_services';
import {Observable} from 'rxjs/Observable';
import {debounceTime, map, startWith} from 'rxjs/operators';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DialogService} from 'ng2-bootstrap-modal';
import {DatePipe} from '@angular/common'
import {DateAdapter, MatDialog} from '@angular/material';
import {AddressComponent} from '../_directives/address.component';

export const _filter = (opt: object[], value: string): object[] => {
  console.log('_filter', typeof value, value);
  if (value) {
    const filterValue = value.toLowerCase();
    // tslint:disable-next-line:max-line-length
    const filtered = opt.filter(item => item['name'].toLowerCase().indexOf(filterValue) >= 0 || item['description'].toLowerCase().indexOf(filterValue) >= 0).reduce((res, r) => {
      res[r['id']] = r;
      return res;
    }, {});
    const results = Object.keys(filtered).map(k => filtered[k]);
    console.log('results', filterValue, results);
    return results;
  }
  return opt;
};


@Component({
  selector: 'app-root',
  template: `
    <ng-container *ngIf="whitelabel">
      <nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
        <div class="container">
          <a class="navbar-brand" href="/"><img src="{{whitelabel.logo}}" alt="{{whitelabel.title}}" class="pull-left"></a>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive"
                  aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarResponsive">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <a class="nav-link" href="/logout" routerLink="/logout"><i class="fas fa-door-open"></i> Log-out</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div class="content-wrapper">
        <div class="container">

          <div class="row">
            <div class="col-sm-12 col-md-12"
                 *ngIf="!whitelabel.client_bookings_limit || whitelabel.client_bookings_limit > active_appointments">

              <mat-horizontal-stepper [linear]="true" #stepper>
                <mat-step [stepControl]="searchForm">
                  <ng-template matStepLabel>
                    <app-loading *ngIf="loading_calendars"></app-loading>
                    <span class="hide-on-small-and-down">Find available time slots</span>
                    <span class="hide-on-med-and-up">Start</span>
                  </ng-template>
                  <ng-container *ngIf="loading_calendars">
                    <h4>Page loading, please wait</h4>
                  </ng-container>
                  <form [formGroup]="searchForm" class="form" (ngSubmit)="onSearchTimeslots()" *ngIf="!loading_calendars">

                    <div *ngIf="whitelabel.ask_home_address && searchForm.contains('address_id')" style="width:400px;">
                      <div class="mb-3">
                        <div class="form-group-lg">
                          <mat-form-field style="width:100%">
                            <mat-label>{{whitelabel.ask_home_address_label}}</mat-label>
                            <mat-select formControlName="address_id">
                              <mat-option *ngFor="let a of addresses" [value]="a.id">
                                {{a.address}}, {{a.postcode}}, {{a.country}}
                              </mat-option>
                              <mat-option (click)="openNewAdress()"> - Add new address -</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="whitelabel.allow_consultant_selection && calendars.length > 1" class="mb-6">
                      <div class="form-group-lg">
                        <h4>{{whitelabel.consultant_label}}</h4>
                        <div class="row">
                          <div class="col-xs-12 col-md-6" *ngFor="let c of calendars">
                            <label
                              *ngIf="!searchForm.get('type').value || c._appointmentTypes.indexOf(searchForm.get('type').value) >= 0"><input
                              type="radio" formControlName="calendar" [value]="c.id" required> {{c.name}}</label>
                            <label *ngIf="searchForm.get('type').value && c._appointmentTypes.indexOf(searchForm.get('type').value) < 0"
                                   class="text-muted"><input type="radio" disabled> {{c.name}}</label>
                          </div>
                          <div class="col-xs-12 col-md-6">
                            <label style="font-style: italic;"><input type="radio" formControlName="calendar" [value]="0" required> * Any *</label>
                          </div>
                        </div>
                      </div>

                      <div class="form-group-lg mb-3" *ngIf="groupedAppointmentTypesLength > 1">
                        <h4>{{whitelabel.type_label}}</h4>
                        <div class="row">
                          <span class="col-xs-12 col-md-6" *ngFor="let t of groupedAppointmentTypes() | keys">
                            <label
                              *ngIf="!searchForm.get('calendar').value || groupedAppointmentTypes(t).group.indexOf(searchForm.get('calendar').value) >= 0 || groupedAppointmentTypes(t).group.indexOf('global') >= 0"><input
                              type="radio" formControlName="type" [value]="t" required> {{t}}</label>
                            <label
                              *ngIf="searchForm.get('calendar').value && groupedAppointmentTypes(t).group.indexOf(searchForm.get('calendar').value) < 0 && groupedAppointmentTypes(t).group.indexOf('global') < 0"
                              class="text-muted"><input type="radio" disabled> {{t}}</label>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="!whitelabel.allow_consultant_selection || calendars.length < 2" class="mb-3">
                      <div class="form-group-lg" *ngIf="appointmentTypesLength > 1">
                        <mat-form-field>
                          <!--  placeholder="{{whitelabel.type_label}}" -->
                          <input type="text" matInput formControlName="type" [matAutocomplete]="auto" [placeholder]="whitelabel.type_label">
                        </mat-form-field>
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                          <mat-optgroup *ngFor="let group of appointmentTypeOptions | async" [label]="group.name">
                            <mat-option *ngFor="let option of group.items" [value]="option">{{option.description}}</mat-option>
                          </mat-optgroup>
                        </mat-autocomplete>
                      </div>
                    </div>

                    <div class="form-group-lg mb-3">
                      <mat-form-field>
                        <input matInput [matDatepicker]="from" formControlName="from" placeholder="When you are available from?"
                               (click)="from.open();" readonly>
                        <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #from></mat-datepicker>
                      </mat-form-field>
                    </div>

                    <div class="form-group-lg">
                      <button [disabled]="searchForm.invalid" class="btn btn-primary" matStepperNext>Continue</button>
                      <span class="ml-2 mr-2"> or </span>
                      <button type="reset" class="btn btn-secondary btn-sm">Reset</button>
                    </div>
                  </form>
                </mat-step>

                <mat-step [stepControl]="bookingForm">
                  <ng-template matStepLabel>
                    <span class="hide-on-small-and-down">Select Available Time Slot</span>
                    <span class="hide-on-med-and-up">Select</span>
                  </ng-template>

                  <div class="mb-5" *ngIf="timeslots.searching">
                    <app-loading></app-loading>
                    Looking for available time slots, please wait...
                  </div>
                  <div class="mb-5" *ngIf="timeslots.searched && !timeslots.searching">
                    <div *ngIf="timeslots.total == 0" class="alert alert-warning">
                      <h4>Unfortunately, there were no available time slots.</h4>
                      <p *ngIf="whitelabel.allow_consultant_selection">Perhaps, try selecting a different consultant.</p>
                    </div>
                    <button mat-button matStepperPrevious class="btn btn-secondary btn-sm" *ngIf="timeslots.total == 0">Try a different
                      search
                    </button>
                    <div *ngIf="timeslots.total == 1">
                      <h4>Great, we found a time slot for you.</h4>
                      <form [formGroup]="bookingForm" class="form" (ngSubmit)="onBookAppointment()">
                        <b>{{timeslots.results[0].formatted.start}}<span
                          *ngIf="whitelabel.show_end_time"> -> {{timeslots.results[0].formatted.end}}</span>
                          ({{timeslots.results[0].timezone}})</b>

                        <div *ngIf="whitelabel.ask_email && bookingForm.contains('email')" style="width:400px;">
                          <h4 class="mt-3">Please provide some additional information</h4>
                          <div *ngIf="whitelabel.ask_email && bookingForm.contains('email')" class="mb-3">
                            <div class="form-group-lg">
                              <mat-form-field>
                                <input type="email" matInput formControlName="email" placeholder="Please confirm your E-mail Address" type="email" [matAutocomplete]="emails" autocomplete="off">
                                <mat-autocomplete #emails="matAutocomplete">
                                  <mat-option *ngFor="let option of filteredEmails | async" [value]="option">
                                    {{option}}
                                  </mat-option>
                                </mat-autocomplete>
                                <mat-error *ngIf="bookingForm.controls['email'].errors?.email">
                                  <mat-icon inline>warning</mat-icon>
                                  Invalid Email Address
                                </mat-error>
                                <mat-error *ngIf="bookingForm.controls['email'].errors?.required">
                                  <mat-icon inline>warning</mat-icon>
                                  Please enter valid Email Address
                                </mat-error>
                              </mat-form-field>
                            </div>
                          </div>
                        </div>

                        <div class="form-group-lg mt-3">
                          <button [disabled]="bookingForm.invalid || booked" class="btn btn-primary" matStepperNext>Book this appointment
                          </button>
                          <span class="ml-2 mr-2"> or </span>
                          <button mat-button matStepperPrevious class="btn btn-secondary btn-sm">Start over</button>
                        </div>
                      </form>
                    </div>
                    <div *ngIf="timeslots.total > 1">
                      <h4>Great, we found {{timeslots.total}} available time slots for you. Please select the one you would like
                        to book.</h4>
                      <p>Note: All times are in {{timeslots.results[0].timezone}} timezone</p>
                      <form [formGroup]="bookingForm" class="form" (ngSubmit)="onBookAppointment()">
                        <div class="radio mt-1" *ngFor="let ts of timeslots.results">
                          <label><input type="radio" formControlName="timeslot" [value]="ts" required> {{ts.formatted.start}}<span
                            *ngIf="whitelabel.show_end_time"> ->
                            {{ts.formatted.end}}</span></label>
                        </div>

                        <div *ngIf="whitelabel.ask_email && bookingForm.contains('email')" style="width:400px;">
                          <h4>Please provide some additional information</h4>
                          <div *ngIf="whitelabel.ask_email && bookingForm.contains('email')" class="mb-3">
                            <div class="form-group-lg">
                              <mat-form-field>
                                <input matInput formControlName="email" placeholder="Please confirm your E-mail Address" type="email">
                              </mat-form-field>
                            </div>
                          </div>
                        </div>

                        <div class="form-group-lg mt-3">
                          <button [disabled]="bookingForm.invalid || booked" class="btn btn-primary" matStepperNext>Book the selected
                            appointment
                          </button>
                          <span class="ml-2 mr-2"> or </span>
                          <button mat-button matStepperPrevious class="btn btn-secondary btn-sm">Start over</button>
                        </div>
                      </form>
                    </div>
                  </div>

                </mat-step>

                <mat-step>
                  <ng-template matStepLabel>Done</ng-template>
                  <div class="row">
                    <div class="col-sm-12 col-md-9">
                      <alert></alert>
                    </div>
                    <div class="col-sm-12 col-md-3" style="height:150px;">
                      <p><span class="btn-group btn-block" *ngIf="showAddToCalendar">
                    <button type="button" class="btn btn-primary btn-block dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                      Add to Calendar
                    </button>
                    <ul class="dropdown-menu">
                      <li><a [href]="recentlyBookedEvent.google" target="_blank">Google Calendar</a></li>
                      <li><a [href]="recentlyBookedEvent.ics" download>iCal</a></li>
                      <li><a [href]="recentlyBookedEvent.ics" download>Outlook</a></li>
                      <li><a [href]="recentlyBookedEvent.yahoo" target="_blank">Yahoo! Calendar</a></li>
                    </ul>
                  </span>
                      </p>
                      <p>
                        <button mat-button class="btn btn-secondary btn-block" (click)="stepper.reset()">Make another booking</button>
                      </p>
                    </div>
                  </div>
                </mat-step>
              </mat-horizontal-stepper>

            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 col-md-12">
              <hr/>

              <div *ngIf="total_appointments > 0">
                <h3>Your appointments</h3>
                <div *ngFor="let g of appointments | keys " class="mt-3">
                  <div *ngIf="g!='CANCELED'">
                    <hr/>
                    <h5 class="text-capitalize">{{g|lowercase}}</h5>
                    <table class="table table-hover" *ngIf="g!='CANCELED'">
                      <thead>
                      <tr>
                        <th width="20%">Date</th>
                        <th width="20%">Time</th>
                        <th>Description</th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let a of appointments[g]">
                        <td>{{a.start | date : "EEEE, d MMM"}}</td>
                        <td>{{a.start | date : "shortTime"}}</td>
                        <td>{{a.appointment_type.name}}</td>
                        <td class="text-right">
                          <div class="btn-group">
                         <span class="btn-group" *ngIf="a.allowed_operations.indexOf('cancel') >= 0">
                          <button type="button" class="btn btn-primary btn-block dropdown-toggle" data-toggle="dropdown"
                                  aria-haspopup="true" aria-expanded="false">
                            <span class="hide-on-med-and-down">Add to Calendar</span>
                            <span class="hide-on-large-only"><i class="fa fa-calendar-plus"></i></span>
                          </button>
                          <ul class="dropdown-menu">
                            <li><a [href]="a.google" target="_blank">Google Calendar</a></li>
                            <li><a [href]="a.ics" download>iCal</a></li>
                            <li><a [href]="a.ics" download>Outlook</a></li>
                            <li><a [href]="a.yahoo" target="_blank">Yahoo! Calendar</a></li>
                          </ul>
                        </span>
                            <button type="button" *ngIf="a.allowed_operations.indexOf('confirm') >= 0"
                                    class="btn btn-sm btn-info"
                                    (click)="confirmAppointment(a)">
                              <span class="hide-on-med-and-down">Confirm</span>
                              <span class="hide-on-large-only"><i class="fa fa-check"></i></span>
                            </button>
                            <button type="button" *ngIf="a.allowed_operations.indexOf('cancel') >= 0"
                                    class="btn btn-sm btn-danger" (click)="cancelAppointment(a)">
                              <span class="hide-on-med-and-down">Cancel</span>
                              <span class="hide-on-large-only"><i class="fa fa-ban"></i></span>
                            </button>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div *ngIf="appointments['CANCELED']">
                  <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseExample"
                          aria-expanded="false" aria-controls="collapseExample">
                    Show cancelled bookings
                  </button>
                  <div class="collapse" id="collapseExample">
                    <table class="table table-hover">
                      <thead>
                      <tr>
                        <th width="20%">Date</th>
                        <th width="20%">Time</th>
                        <th>Description</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let a of appointments['CANCELED']">
                        <td>{{a.start | date : "EEEE, d MMM"}}</td>
                        <td>{{a.start | date : "shortTime"}}</td>
                        <td>{{a.appointment_type.name}}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="help-block" *ngIf="!appointments">You don't have upcoming appointments for now.</div>
            </div>
          </div>
          <br/>
          <div class="row" *ngIf="whitelabel.page_footer">
            <div class="col-12 mt-lg" [innerHTML]="page_footer()"></div>
          </div>
        </div>
      </div>
    </ng-container>
  `
})
export class MainComponent implements OnInit {
  whitelabel: any;
  calendar: any;
  loading_calendars = true;
  calendars = [];
  event: any;
  searchForm: FormGroup;
  bookingForm: FormGroup;
  timeslots: { searched: boolean, searching: boolean, results: any, total: number };
  booked: boolean;
  appointments = {'CANCELED': []};
  total_appointments = 0;
  active_appointments = 0;
  dialogs: any;
  isLinear = true;
  appointmentTypeOptions: Observable<AppointmentType[]>;
  showAddToCalendar = false;
  recentlyBookedEvent: any;
  addresses = [];
  emails = [];
  filteredEmails: Observable<string[]>;

  constructor(
    private route: ActivatedRoute,
    private globalSettings: GlobalSettings,
    private alertService: AlertService,
    private calResource: CalendarsResource,
    private appointmentsResource: AppointmentResource,
    private clientResource: ClientResource,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.whitelabel = globalSettings.whitelabel;
    globalSettings.whitelabel$.subscribe(val => {
      this.whitelabel = val;
      this.loadCalendars();
    });

    this.timeslots = {searched: false, searching: false, results: [], total: -1};

    dateAdapter.setLocale('en-gb');

    this.dialogs = {};

    const dp = new DatePipe(navigator.language);
    const dsdo = this.whitelabel ? this.whitelabel.default_search_days_offset : 0;
    const date = new Date();
    date.setDate(date.getDate() + (dsdo || 0));
    const dtr = dp.transform(date, 'y-MM-dd');

    this.searchForm = this.fb.group({
      calendar: [sessionStorage.getItem('calendar'), Validators.nullValidator],
      type: [sessionStorage.getItem('type'), Validators.required],
      from: [dtr, Validators.required],
    });

    this.searchForm.valueChanges
      .pipe(
        debounceTime(500),
        startWith(''),
        map(data => {
          Object.keys(data).forEach(k => {
            if (typeof data[k] !== 'undefined' && data[k] && data[k] !== 'null') {
              sessionStorage.setItem(k, data[k]);
            }
          });
        })
      ).subscribe();

    // @ts-ignore

    this.bookingForm = fb.group({
      timeslot: ['', Validators.required]
    });

    this.bookingForm.valueChanges
      .pipe(
        debounceTime(500),
        startWith(''),
        map(data => {
          Object.keys(data).forEach(k => {
            if (typeof data[k] !== 'undefined' && data[k] && data[k] !== 'null') {
              sessionStorage.setItem(k, data[k]);
            }
          });
        })
      ).subscribe();

    if (this.whitelabel) {
      this.loadCalendars();
    }

    this.loadAppointments();
  }

  get groupedAppointmentTypesLength(): Observable<number> {
    // @ts-ignore
    return Object.keys(this.groupedAppointmentTypes()).length;
  }

  get appointmentTypes(): Observable<AppointmentType[]> {
    const res = [];
    const indexes = {};
    // if (this.whitelabel.appointment_types) {
    //   this.whitelabel.appointment_types.forEach(a => {
    //     if (!indexes.hasOwnProperty(a.name)) {
    //       indexes[a.name] = res.length;
    //       res.push({name: a.name, items: []});
    //     }
    //     if (!a.description) { a.description = a.name; }
    //     res[indexes[a.name]].items.push(a);
    //   });
    // }
    this.calendars.forEach(c => {
      c.appointment_types.forEach(a => {
        if (!indexes.hasOwnProperty(a.name)) {
          indexes[a.name] = res.length;
          res.push({name: a.name, items: []});
        }
        if (!a.description) {
          a.description = a.name;
        }
        res[indexes[a.name]].items.push(a);
      });
    });
    // @ts-ignore
    return res;
  }

  get appointmentTypesLength(): Observable<number> {
    // @ts-ignore
    return Object.keys(this.appointmentTypes).length;
  }

  displayFn(option?: any): SafeHtml | undefined {
    return (option ? (option.name === option.description ? option.name : `${option.name}  ${option.description}`) : undefined);
  }

  ngOnInit() {
    this.appointmentTypeOptions = this.searchForm.get('type')!.valueChanges
      .pipe(
        startWith<string>(''),
        map(value => this._filterGroup(value)) // filter on the type field.
      );
  }

  onSearchTimeslots(): void {
    this.booked = false;
    this.timeslots.searched = true;
    this.timeslots.searching = true;
    if (this.calendars.length === 1) {
      this.searchForm.value.calendar = this.calendars[0].id;
    }

    // @ts-ignore
    if (this.appointmentTypesLength === 1 && this.searchForm.value.type === '') {
      this.searchForm.value.type = Object.keys(this.groupedAppointmentTypes())[0];
    }

    let ids;
    if (typeof this.searchForm.value.type === 'string') {
      const types = this.groupedAppointmentTypes(this.searchForm.value.type);
      ids = types['ids'] ? types['ids'] : '';
    } else {
      ids = (this.searchForm.value.type ? (this.searchForm.value.type.id ? this.searchForm.value.type.id : this.searchForm.value.type.ids) : '');
    }

    const dp = new DatePipe(navigator.language);
    console.log('From Date (%s) => %s', this.searchForm.value.from, dp.transform(this.searchForm.value.from, 'yyyy-MM-dd'));

    // @ts-ignore
    this.appointmentsResource.get_timeslots(ids, dp.transform(this.searchForm.value.from, 'yyyy-MM-dd'), this.searchForm.value.calendar, this.searchForm.value.address_id).subscribe(
      results => {
        this.timeslots.results = results.map(rs => {
          rs.formatted = {
            // @ts-ignore
            start: dp.transform(rs.start, 'medium'),
            // @ts-ignore
            end: dp.transform(rs.end, 'shortTime')
          };
          return rs;
        });
        this.timeslots.searching = false;
        this.timeslots.total = results.length;
        if (this.timeslots.total === 1) {
          this.bookingForm.controls['timeslot'].setValue(this.timeslots.results[0]);
        }
      }
    )
  }

  onBookAppointment(): void {
    this.booked = true;
    let ts = this.bookingForm.value.timeslot;

    if (!ts && this.timeslots.total === 1) {
      ts = this.timeslots.results[0];
    }

    // @ts-ignore
    this.appointmentsResource.book(ts, this.bookingForm.value.email).subscribe(
      event => {

        const dp = new DatePipe(navigator.language);
        const dtstart = dp.transform(event.start, 'yyyMMddTHHmmss');
        const dtend = dp.transform(event.end, 'yyyyMMddTHHmmss');
        // const dtstamp = (new Date(event.start)).toISOString();
        const descr = event.appointment_type.description.replace('\n', '\\n');
        const vcard = [`BEGIN:VCALENDAR`,
          `PRODID:-//ZTimeSlot.guru//TimeSlot Guru Event 1.0//EN`,
          `VERSION:2.0`,
          `CALSCALE:GREGORIAN`,
          `BEGIN:VEVENT`,
          `DTSTAMP:${dtstart}`,
          `UID:${event.id}`,
          `SUMMARY:${event.appointment_type.name}`,
          `DTSTART:${dtstart}`,
          `DTEND:${dtend}`,
          `DESCRIPTION: ${descr}`,
          `URL:${location.href}`,
          `STATUS:${event.status}`,
          // `SEQUENCE:3`,
          `BEGIN:VALARM`,
          `TRIGGER:-PT24H`,
          `DESCRIPTION: 1 day until your appointment ${event.appointment_type.name}`,
          `ACTION:DISPLAY`,
          `END:VALARM`,
          `BEGIN:VALARM`,
          `TRIGGER:-PT60M`,
          `DESCRIPTION: 1 hour until your appointment ${event.appointment_type.name}`,
          `ACTION:DISPLAY`,
          `END:VALARM`,
          `END:VEVENT`,
          `END:VCALENDAR`];

        event.ics = this.sanitizer.bypassSecurityTrustResourceUrl('data:text/calendar;base64,' + btoa(vcard.join('\r\n')));

        // tslint:disable-next-line:max-line-length
        event.google = encodeURI(`https://www.google.com/calendar/event?action=TEMPLATE&sprop=name:${event.appointment_type.name}&sprop=website:${window.location.href}&details=${event.appointment_type.description}&text=${event.appointment_type.name}&dates=${dtstart}/${dtend}`);

        const h = Math.floor(event.appointment_type.duration / 60);
        const m = event.appointment_type.duration % 60;
        const duration = String(h < 10 ? '0' + String(h) : h) + String(m < 10 ? '0' + String(m) : m);
        // tslint:disable-next-line:max-line-length
        event.yahoo = encodeURI(`https://calendar.yahoo.com/?v=60&VIEW=d&TYPE=20&TITLE=${event.appointment_type.name}&ST=${dtstart}&DUR=${duration}&URL=${window.location.href}&DESC=${event.appointment_type.description}`);

        this.showAddToCalendar = event.status === 'CONFIRMED';

        this.recentlyBookedEvent = event;

        this.alertService.success(this.sanitizer.bypassSecurityTrustHtml(this.whitelabel.confirmation_body));
        this.timeslots = {searched: false, searching: false, results: [], total: -1};
        this.loadAppointments();
      },
      error => {
        this.alertService.error(error.error);
        this.booked = false;
      }
    )
  }

  confirmAppointment(a): void {
    this.appointmentsResource.confirm(a.calendar_id, a.id).subscribe(response => {
      this.alertService.success('Your appointment has been confirmed!');
      this.loadAppointments();
    }, error => {
      this.alertService.error(error.error);
    });
  }

  cancelAppointment(a): void {
    if (confirm('Are you sure you want to cancel this appointment?\nYou may not be able to re-book the same time slot.')) {
      this.appointmentsResource.cancel(a.calendar_id, a.id).subscribe(response => {
        this.alertService.info('Your appointment has been cancelled!');
        this.loadAppointments();
      }, error => {
        this.alertService.error(error.error);
      });
    }
  }

  openNewAdress(): void {
    this.dialog
      .open(AddressComponent, {
        width: '400px',
        role: 'alertdialog',
        data: {}
      })
      .afterClosed()
      .subscribe($newAddressId => {
        if ($newAddressId) {
          this.clientResource.get_data({client: sessionStorage.getItem('currentAppointmentUser')}).subscribe(data => {
            this.addresses = data['addresses'];
            this.emails = data['emails'];
            this.searchForm.patchValue({
              address_id: $newAddressId
            });
          }, error => {
            console.warn(error);
          });
        }
      }
    );
  }

  page_footer(): any {
    return this.sanitizer.bypassSecurityTrustHtml(this.whitelabel.page_footer);
  }

  groupedAppointmentTypes(type?: any): Observable<any[]> {
    const res = {};
    // if (this.whitelabel.appointment_types) {
    //   this.whitelabel.appointment_types.forEach(a => {
    //     if (!res.hasOwnProperty(a.name)) {
    //       res[a.name] = {group: ['global'], name: a.name, description: a.description, ids: ''};
    //     }
    //     res[a.name].ids += a.id + ',';
    //   });
    // }
    this.calendars.forEach(c => {
      c.appointment_types.forEach(a => {
        if (!res.hasOwnProperty(a.name)) {
          res[a.name] = {group: [], name: a.name, description: a.description, ids: ''};
        }
        res[a.name].group.push(c.id);
        res[a.name].ids += a.id + ',';
      });
    });
    if (typeof type === 'undefined' || !type) {
      // @ts-ignore
      return res;
    } else {
      return res[type];
    }
  }

  private loadAppointments(): void {
    // @ts-ignore
    const from = new Date();
    const to = new Date();
    to.setDate(to.getDate() + 30);
    this.clientResource.get_events(from.toISOString(), to.toISOString()).subscribe(results => {
      this.total_appointments = results.length;
      this.active_appointments = results.length;
      this.appointments = results.reduce((res, event) => {
        if (!res.hasOwnProperty(event.status)) {
          res[event.status] = [];
        }

        if (event.status === 'CANCELED') {
          this.active_appointments--;
        }

        const dp = new DatePipe(navigator.language);
        const dtstart = dp.transform(event.start, 'yyyMMddTHHmmss');
        const dtend = dp.transform(event.end, 'yyyyMMddTHHmmss');
        // const dtstamp = (new Date(event.start)).toISOString();
        const descr = event.appointment_type.description.replace('\n', '\\n');
        const vcard = [`BEGIN:VCALENDAR`,
          `PRODID:-//ZTimeSlot.guru//TimeSlot Guru Event 1.0//EN`,
          `VERSION:2.0`,
          `CALSCALE:GREGORIAN`,
          `BEGIN:VEVENT`,
          `DTSTAMP:${dtstart}`,
          `UID:${event.id}`,
          `SUMMARY:${event.appointment_type.name}`,
          `DTSTART:${dtstart}`,
          `DTEND:${dtend}`,
          `DESCRIPTION: ${descr}`,
          `URL:${location.href}`,
          `STATUS:${event.status}`,
          // `SEQUENCE:3`,
          `BEGIN:VALARM`,
          `TRIGGER:-PT24H`,
          `DESCRIPTION: 1 day until your appointment ${event.appointment_type.name}`,
          `ACTION:DISPLAY`,
          `END:VALARM`,
          `BEGIN:VALARM`,
          `TRIGGER:-PT60M`,
          `DESCRIPTION: 1 hour until your appointment ${event.appointment_type.name}`,
          `ACTION:DISPLAY`,
          `END:VALARM`,
          `END:VEVENT`,
          `END:VCALENDAR`];

        event.ics = this.sanitizer.bypassSecurityTrustResourceUrl('data:text/calendar;base64,' + btoa(vcard.join('\r\n')));

        // tslint:disable-next-line:max-line-length
        event.google = encodeURI(`https://www.google.com/calendar/event?action=TEMPLATE&sprop=name:${event.appointment_type.name}&sprop=website:${window.location.href}&details=${event.appointment_type.description}&text=${event.appointment_type.name}&dates=${dtstart}/${dtend}`);

        const h = Math.floor(event.appointment_type.duration / 60);
        const m = event.appointment_type.duration % 60;
        const duration = String(h < 10 ? '0' + String(h) : h) + String(m < 10 ? '0' + String(m) : m);
        // tslint:disable-next-line:max-line-length
        event.yahoo = encodeURI(`https://calendar.yahoo.com/?v=60&VIEW=d&TYPE=20&TITLE=${event.appointment_type.name}&ST=${dtstart}&DUR=${duration}&URL=${window.location.href}&DESC=${event.appointment_type.description}`);

        res[event.status].push(event);
        return res;
      }, {});
    });
  }

  private loadCalendars(): void {
    this.loading_calendars = true;
    this.calResource.get().subscribe(results => {
      this.calendars = results.map(c => {
        c._appointmentTypes = c.appointment_types.reduce((types, t) => {
          types.push(t.name);
          return types;
        }, []);
        // if (this.whitelabel.appointment_types) {
        //   this.whitelabel.appointment_types.forEach(a => {
        //     c._appointmentTypes.push(a.name);
        //   });
        // }
        return c;
      });
      this.loading_calendars = false;
      if (this.calendars.length === 1) {
        this.searchForm.controls['calendar'].setValue(this.calendars[0].id);
      }
      // @ts-ignore
      if (this.appointmentTypesLength === 1) {
        this.searchForm.controls['type'].setValue(this.groupedAppointmentTypes()[Object.keys(this.groupedAppointmentTypes())[0]]);
      }
    });

    if (this.whitelabel) {
      if (this.whitelabel.ask_email) {
        this.bookingForm.addControl('email', this.fb.control(sessionStorage.getItem('email'), Validators.compose([Validators.required, Validators.email])));
        this.bookingForm.registerControl('email', this.fb.control(sessionStorage.getItem('email'), Validators.compose([Validators.required, Validators.email])));

        this.filteredEmails = this.bookingForm.controls['email'].valueChanges
          .pipe(
            startWith(''),
            map(value => this._filterEmail(value))
          );
      }

      if (this.whitelabel.ask_home_address) {
        if (sessionStorage.getItem('currentAppointmentUser')) {
          this.clientResource.get_data({client: sessionStorage.getItem('currentAppointmentUser')}).subscribe(data => {
            this.addresses = data['addresses'];
            this.emails = data['emails'];
          }, error => {
            console.warn(error);
          });
        }

        this.searchForm.addControl('address_id', this.fb.control(sessionStorage.getItem('address_id'), Validators.required));
        this.searchForm.registerControl('address_id', this.fb.control(sessionStorage.getItem('address_id'), Validators.required));


      }
    }
  }

  private _filterEmail(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.emails.filter(option => option.toLowerCase().includes(filterValue)).sort((a, b) => a > b ? 1 : (a < b ? -1 : 0));
  }

  private _filterGroup(value: string): AppointmentType[] {
    if (value && typeof value === 'string') {
      // @ts-ignore
      return this.appointmentTypes
        .map(group => ({name: group['name'], items: _filter(group['items'], value)}))
        .filter(group => group.items.length > 0);
    }
    // @ts-ignore
    return this.appointmentTypes;
  }
}
