var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { NativeDateAdapter } from '@angular/material';
import * as moment from 'moment';
var DateFormatAdapter = /** @class */ (function (_super) {
    __extends(DateFormatAdapter, _super);
    function DateFormatAdapter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DateFormatAdapter.prototype.format = function (date, displayFormat) {
        moment.locale('en-GB'); // Choose the locale
        var formatString = (displayFormat === 'input') ? 'DD/MM/YYYY' : 'D MMMM YYYY';
        return moment(date).format(formatString);
    };
    return DateFormatAdapter;
}(NativeDateAdapter));
export { DateFormatAdapter };
