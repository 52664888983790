
    <div class="modal" style="display:block; top:5%; position:absolute;" id="loginModal" *ngIf="whitelabel && whitelabel.id">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text-center" style="margin-bottom:20px;">
              <img src="{{whitelabel.logo}}" alt="{{whitelabel.title}}" style="max-height:200px; max-width:100%;">
            </div>
            <alert></alert>
          </div>
        </div>
      </div>
    </div>
  