import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {GlobalSettings} from '../_providers';
import {AlertService, ClientResource, MarkdownService} from '../_services';
import {FormBuilder} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-calendar',
  template: `
    <div class="content-wrapper">
      <div class="container">
        <alert></alert>
      </div>
    </div>`,
})
export class CalendarComponent implements OnInit, AfterViewInit {
  whitelabel: any;

  constructor(
    private route: ActivatedRoute,
    private globalSettings: GlobalSettings,
    private alertService: AlertService,
    private router: Router,
  ) {
    route.params.subscribe((params: Params) => {
      sessionStorage.setItem('calendar', params['calendarId']);
      router.navigateByUrl('/');
    });
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
  }
}
