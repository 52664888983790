import { Component, OnInit} from '@angular/core';
import { Router} from '@angular/router';
import {AlertService, Settings} from '../_services';
import { GlobalSettings} from '../_providers';

@Component({
  selector: 'app-root',
  template: `
    <div class="modal" style="display:block; top:5%; position:absolute;" id="loginModal" *ngIf="whitelabel && whitelabel.id">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text-center" style="margin-bottom:20px;">
              <img src="{{whitelabel.logo}}" alt="{{whitelabel.title}}" style="max-height:200px; max-width:100%;">
            </div>
            <alert></alert>
          </div>
        </div>
      </div>
    </div>
  `
})

export class LogoutComponent implements OnInit {

  whitelabel: Settings;

  constructor(
    private router: Router,
    private globalSettings: GlobalSettings,
    private alertService: AlertService,
  ) {
  }

  ngOnInit() {
    this.whitelabel = this.globalSettings.whitelabel;
    this.globalSettings.whitelabel$.subscribe((val: Settings) => { this.whitelabel = val; });

    this.globalSettings.setUser(false);
    this.alertService.success(`You've been logged out!`, true);
    sessionStorage.clear();
    // this.router.navigateByUrl('/');
    setTimeout(function() { window.location.href = '/'; }, 3000);
  }

}
