import { Observable } from 'rxjs/Observable';
import {DomSanitizer} from '@angular/platform-browser';
import {Settings} from '../_services';

export class GlobalSettings {
  private wl_observer: any;
  public whitelabel$: Observable<any>;
  public whitelabel: Settings;
  public user: any;
  public user$: Observable<any>;
  private user_observer: any;

  constructor(  ) {
    // this.whitelabel = {};
    this.whitelabel$ = new Observable(observer => this.wl_observer = observer);
    this.user$ = new Observable(observer => this.user_observer = observer);
  }

  public setWhitelabel(data: Settings) {
    this.whitelabel = data;
    if (this.whitelabel && !this.whitelabel.hasOwnProperty('allow_consultant_selection')) {
      this.whitelabel.allow_consultant_selection = false;
    }
    document.title = data.title;
    if (this.wl_observer) {
      this.wl_observer.next(this.whitelabel);
    }
  }

  public setUser(val) {
    if (val) {
      this.user = val;
      sessionStorage.setItem('currentAppointmentUser', val);
    }
    if (this.user_observer) {
      this.user_observer.next(this.user);
    }
  }

}
