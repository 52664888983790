import {AfterViewInit, Component, OnInit} from '@angular/core';
import {AlertService, ClientResource, MarkdownService} from '../_services';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {GlobalSettings} from '../_providers';
import {FormBuilder} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import {DialogService} from 'ng2-bootstrap-modal';

@Component({
  selector: 'app-client',
  template: `
    <div class="content-wrapper">
      <div class="container">
        <alert></alert>
      </div>
    </div>`
})
export class ClientComponent implements OnInit, AfterViewInit {
  whitelabel: any;

  constructor(
    private route: ActivatedRoute,
    private globalSettings: GlobalSettings,
    private alertService: AlertService,
    private router: Router,
    private builder: FormBuilder,
    private sanitizer: DomSanitizer,
    private md: MarkdownService,
    private dialogService: DialogService,
    private client: ClientResource,
  ) {
    route.params.subscribe((params: Params) => {
      client.get_data({client: params['clientId']}).subscribe( data => {
        sessionStorage.setItem('name', data.name);
        sessionStorage.setItem('msisdn', data.msisdn);
        globalSettings.setUser(data.id);
        alertService.success( `Hi ${data.name}`, true);
        // sessionStorage.setItem('message', JSON.stringify({type: 'success', content: `Hi ${data.name}`}));
        router.navigateByUrl('/');
      }, error => {
        alertService.error(error.error);
      })
    });
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
  }

}
