import { NativeDateAdapter } from '@angular/material';
import * as moment from 'moment';
import {Injectable} from '@angular/core';

@Injectable()
export class DateFormatAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    moment.locale('en-GB'); // Choose the locale
    const formatString = (displayFormat === 'input') ? 'DD/MM/YYYY' : 'D MMMM YYYY';
    return moment(date).format(formatString);
  }
}
