/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../_directives/alert.component.ngfactory";
import * as i2 from "../_directives/alert.component";
import * as i3 from "../_services/alert.service";
import * as i4 from "./client.component";
import * as i5 from "@angular/router";
import * as i6 from "../_providers/global-settings";
import * as i7 from "@angular/forms";
import * as i8 from "@angular/platform-browser";
import * as i9 from "../_services/markdown.service";
import * as i10 from "ng2-bootstrap-modal/dist/dialog.service";
import * as i11 from "../_services/api.service";
var styles_ClientComponent = [];
var RenderType_ClientComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ClientComponent, data: {} });
export { RenderType_ClientComponent as RenderType_ClientComponent };
export function View_ClientComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "content-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "alert", [], null, null, null, i1.View_AlertComponent_0, i1.RenderType_AlertComponent)), i0.ɵdid(3, 180224, null, 0, i2.AlertComponent, [i3.AlertService], null, null)], null, null); }
export function View_ClientComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-client", [], null, null, null, View_ClientComponent_0, RenderType_ClientComponent)), i0.ɵdid(1, 4308992, null, 0, i4.ClientComponent, [i5.ActivatedRoute, i6.GlobalSettings, i3.AlertService, i5.Router, i7.FormBuilder, i8.DomSanitizer, i9.MarkdownService, i10.DialogService, i11.ClientResource], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ClientComponentNgFactory = i0.ɵccf("app-client", i4.ClientComponent, View_ClientComponent_Host_0, {}, {}, []);
export { ClientComponentNgFactory as ClientComponentNgFactory };
