import { OnInit } from '@angular/core';
import { GlobalSettings } from '../_providers';
import { ActivatedRoute } from '@angular/router';
import { AlertService, AppointmentResource, CalendarsResource, ClientResource } from '../_services';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { DateAdapter, MatDialog } from '@angular/material';
import { AddressComponent } from '../_directives/address.component';
export var _filter = function (opt, value) {
    console.log('_filter', typeof value, value);
    if (value) {
        var filterValue_1 = value.toLowerCase();
        // tslint:disable-next-line:max-line-length
        var filtered_1 = opt.filter(function (item) { return item['name'].toLowerCase().indexOf(filterValue_1) >= 0 || item['description'].toLowerCase().indexOf(filterValue_1) >= 0; }).reduce(function (res, r) {
            res[r['id']] = r;
            return res;
        }, {});
        var results = Object.keys(filtered_1).map(function (k) { return filtered_1[k]; });
        console.log('results', filterValue_1, results);
        return results;
    }
    return opt;
};
var MainComponent = /** @class */ (function () {
    function MainComponent(route, globalSettings, alertService, calResource, appointmentsResource, clientResource, fb, dialog, sanitizer, dateAdapter) {
        var _this = this;
        this.route = route;
        this.globalSettings = globalSettings;
        this.alertService = alertService;
        this.calResource = calResource;
        this.appointmentsResource = appointmentsResource;
        this.clientResource = clientResource;
        this.fb = fb;
        this.dialog = dialog;
        this.sanitizer = sanitizer;
        this.dateAdapter = dateAdapter;
        this.loading_calendars = true;
        this.calendars = [];
        this.appointments = { 'CANCELED': [] };
        this.total_appointments = 0;
        this.active_appointments = 0;
        this.isLinear = true;
        this.showAddToCalendar = false;
        this.addresses = [];
        this.emails = [];
        this.whitelabel = globalSettings.whitelabel;
        globalSettings.whitelabel$.subscribe(function (val) {
            _this.whitelabel = val;
            _this.loadCalendars();
        });
        this.timeslots = { searched: false, searching: false, results: [], total: -1 };
        dateAdapter.setLocale('en-gb');
        this.dialogs = {};
        var dp = new DatePipe(navigator.language);
        var dsdo = this.whitelabel ? this.whitelabel.default_search_days_offset : 0;
        var date = new Date();
        date.setDate(date.getDate() + (dsdo || 0));
        var dtr = dp.transform(date, 'y-MM-dd');
        this.searchForm = this.fb.group({
            calendar: [sessionStorage.getItem('calendar'), Validators.nullValidator],
            type: [sessionStorage.getItem('type'), Validators.required],
            from: [dtr, Validators.required],
        });
        this.searchForm.valueChanges
            .pipe(debounceTime(500), startWith(''), map(function (data) {
            Object.keys(data).forEach(function (k) {
                if (typeof data[k] !== 'undefined' && data[k] && data[k] !== 'null') {
                    sessionStorage.setItem(k, data[k]);
                }
            });
        })).subscribe();
        // @ts-ignore
        this.bookingForm = fb.group({
            timeslot: ['', Validators.required]
        });
        this.bookingForm.valueChanges
            .pipe(debounceTime(500), startWith(''), map(function (data) {
            Object.keys(data).forEach(function (k) {
                if (typeof data[k] !== 'undefined' && data[k] && data[k] !== 'null') {
                    sessionStorage.setItem(k, data[k]);
                }
            });
        })).subscribe();
        if (this.whitelabel) {
            this.loadCalendars();
        }
        this.loadAppointments();
    }
    Object.defineProperty(MainComponent.prototype, "groupedAppointmentTypesLength", {
        get: function () {
            // @ts-ignore
            return Object.keys(this.groupedAppointmentTypes()).length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MainComponent.prototype, "appointmentTypes", {
        get: function () {
            var res = [];
            var indexes = {};
            // if (this.whitelabel.appointment_types) {
            //   this.whitelabel.appointment_types.forEach(a => {
            //     if (!indexes.hasOwnProperty(a.name)) {
            //       indexes[a.name] = res.length;
            //       res.push({name: a.name, items: []});
            //     }
            //     if (!a.description) { a.description = a.name; }
            //     res[indexes[a.name]].items.push(a);
            //   });
            // }
            this.calendars.forEach(function (c) {
                c.appointment_types.forEach(function (a) {
                    if (!indexes.hasOwnProperty(a.name)) {
                        indexes[a.name] = res.length;
                        res.push({ name: a.name, items: [] });
                    }
                    if (!a.description) {
                        a.description = a.name;
                    }
                    res[indexes[a.name]].items.push(a);
                });
            });
            // @ts-ignore
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MainComponent.prototype, "appointmentTypesLength", {
        get: function () {
            // @ts-ignore
            return Object.keys(this.appointmentTypes).length;
        },
        enumerable: true,
        configurable: true
    });
    MainComponent.prototype.displayFn = function (option) {
        return (option ? (option.name === option.description ? option.name : option.name + "  " + option.description) : undefined);
    };
    MainComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appointmentTypeOptions = this.searchForm.get('type').valueChanges
            .pipe(startWith(''), map(function (value) { return _this._filterGroup(value); }) // filter on the type field.
        );
    };
    MainComponent.prototype.onSearchTimeslots = function () {
        var _this = this;
        this.booked = false;
        this.timeslots.searched = true;
        this.timeslots.searching = true;
        if (this.calendars.length === 1) {
            this.searchForm.value.calendar = this.calendars[0].id;
        }
        // @ts-ignore
        if (this.appointmentTypesLength === 1 && this.searchForm.value.type === '') {
            this.searchForm.value.type = Object.keys(this.groupedAppointmentTypes())[0];
        }
        var ids;
        if (typeof this.searchForm.value.type === 'string') {
            var types = this.groupedAppointmentTypes(this.searchForm.value.type);
            ids = types['ids'] ? types['ids'] : '';
        }
        else {
            ids = (this.searchForm.value.type ? (this.searchForm.value.type.id ? this.searchForm.value.type.id : this.searchForm.value.type.ids) : '');
        }
        var dp = new DatePipe(navigator.language);
        console.log('From Date (%s) => %s', this.searchForm.value.from, dp.transform(this.searchForm.value.from, 'yyyy-MM-dd'));
        // @ts-ignore
        this.appointmentsResource.get_timeslots(ids, dp.transform(this.searchForm.value.from, 'yyyy-MM-dd'), this.searchForm.value.calendar, this.searchForm.value.address_id).subscribe(function (results) {
            _this.timeslots.results = results.map(function (rs) {
                rs.formatted = {
                    // @ts-ignore
                    start: dp.transform(rs.start, 'medium'),
                    // @ts-ignore
                    end: dp.transform(rs.end, 'shortTime')
                };
                return rs;
            });
            _this.timeslots.searching = false;
            _this.timeslots.total = results.length;
            if (_this.timeslots.total === 1) {
                _this.bookingForm.controls['timeslot'].setValue(_this.timeslots.results[0]);
            }
        });
    };
    MainComponent.prototype.onBookAppointment = function () {
        var _this = this;
        this.booked = true;
        var ts = this.bookingForm.value.timeslot;
        if (!ts && this.timeslots.total === 1) {
            ts = this.timeslots.results[0];
        }
        // @ts-ignore
        this.appointmentsResource.book(ts, this.bookingForm.value.email).subscribe(function (event) {
            var dp = new DatePipe(navigator.language);
            var dtstart = dp.transform(event.start, 'yyyMMddTHHmmss');
            var dtend = dp.transform(event.end, 'yyyyMMddTHHmmss');
            // const dtstamp = (new Date(event.start)).toISOString();
            var descr = event.appointment_type.description.replace('\n', '\\n');
            var vcard = ["BEGIN:VCALENDAR",
                "PRODID:-//ZTimeSlot.guru//TimeSlot Guru Event 1.0//EN",
                "VERSION:2.0",
                "CALSCALE:GREGORIAN",
                "BEGIN:VEVENT",
                "DTSTAMP:" + dtstart,
                "UID:" + event.id,
                "SUMMARY:" + event.appointment_type.name,
                "DTSTART:" + dtstart,
                "DTEND:" + dtend,
                "DESCRIPTION: " + descr,
                "URL:" + location.href,
                "STATUS:" + event.status,
                // `SEQUENCE:3`,
                "BEGIN:VALARM",
                "TRIGGER:-PT24H",
                "DESCRIPTION: 1 day until your appointment " + event.appointment_type.name,
                "ACTION:DISPLAY",
                "END:VALARM",
                "BEGIN:VALARM",
                "TRIGGER:-PT60M",
                "DESCRIPTION: 1 hour until your appointment " + event.appointment_type.name,
                "ACTION:DISPLAY",
                "END:VALARM",
                "END:VEVENT",
                "END:VCALENDAR"];
            event.ics = _this.sanitizer.bypassSecurityTrustResourceUrl('data:text/calendar;base64,' + btoa(vcard.join('\r\n')));
            // tslint:disable-next-line:max-line-length
            event.google = encodeURI("https://www.google.com/calendar/event?action=TEMPLATE&sprop=name:" + event.appointment_type.name + "&sprop=website:" + window.location.href + "&details=" + event.appointment_type.description + "&text=" + event.appointment_type.name + "&dates=" + dtstart + "/" + dtend);
            var h = Math.floor(event.appointment_type.duration / 60);
            var m = event.appointment_type.duration % 60;
            var duration = String(h < 10 ? '0' + String(h) : h) + String(m < 10 ? '0' + String(m) : m);
            // tslint:disable-next-line:max-line-length
            event.yahoo = encodeURI("https://calendar.yahoo.com/?v=60&VIEW=d&TYPE=20&TITLE=" + event.appointment_type.name + "&ST=" + dtstart + "&DUR=" + duration + "&URL=" + window.location.href + "&DESC=" + event.appointment_type.description);
            _this.showAddToCalendar = event.status === 'CONFIRMED';
            _this.recentlyBookedEvent = event;
            _this.alertService.success(_this.sanitizer.bypassSecurityTrustHtml(_this.whitelabel.confirmation_body));
            _this.timeslots = { searched: false, searching: false, results: [], total: -1 };
            _this.loadAppointments();
        }, function (error) {
            _this.alertService.error(error.error);
            _this.booked = false;
        });
    };
    MainComponent.prototype.confirmAppointment = function (a) {
        var _this = this;
        this.appointmentsResource.confirm(a.calendar_id, a.id).subscribe(function (response) {
            _this.alertService.success('Your appointment has been confirmed!');
            _this.loadAppointments();
        }, function (error) {
            _this.alertService.error(error.error);
        });
    };
    MainComponent.prototype.cancelAppointment = function (a) {
        var _this = this;
        if (confirm('Are you sure you want to cancel this appointment?\nYou may not be able to re-book the same time slot.')) {
            this.appointmentsResource.cancel(a.calendar_id, a.id).subscribe(function (response) {
                _this.alertService.info('Your appointment has been cancelled!');
                _this.loadAppointments();
            }, function (error) {
                _this.alertService.error(error.error);
            });
        }
    };
    MainComponent.prototype.openNewAdress = function () {
        var _this = this;
        this.dialog
            .open(AddressComponent, {
            width: '400px',
            role: 'alertdialog',
            data: {}
        })
            .afterClosed()
            .subscribe(function ($newAddressId) {
            if ($newAddressId) {
                _this.clientResource.get_data({ client: sessionStorage.getItem('currentAppointmentUser') }).subscribe(function (data) {
                    _this.addresses = data['addresses'];
                    _this.emails = data['emails'];
                    _this.searchForm.patchValue({
                        address_id: $newAddressId
                    });
                }, function (error) {
                    console.warn(error);
                });
            }
        });
    };
    MainComponent.prototype.page_footer = function () {
        return this.sanitizer.bypassSecurityTrustHtml(this.whitelabel.page_footer);
    };
    MainComponent.prototype.groupedAppointmentTypes = function (type) {
        var res = {};
        // if (this.whitelabel.appointment_types) {
        //   this.whitelabel.appointment_types.forEach(a => {
        //     if (!res.hasOwnProperty(a.name)) {
        //       res[a.name] = {group: ['global'], name: a.name, description: a.description, ids: ''};
        //     }
        //     res[a.name].ids += a.id + ',';
        //   });
        // }
        this.calendars.forEach(function (c) {
            c.appointment_types.forEach(function (a) {
                if (!res.hasOwnProperty(a.name)) {
                    res[a.name] = { group: [], name: a.name, description: a.description, ids: '' };
                }
                res[a.name].group.push(c.id);
                res[a.name].ids += a.id + ',';
            });
        });
        if (typeof type === 'undefined' || !type) {
            // @ts-ignore
            return res;
        }
        else {
            return res[type];
        }
    };
    MainComponent.prototype.loadAppointments = function () {
        var _this = this;
        // @ts-ignore
        var from = new Date();
        var to = new Date();
        to.setDate(to.getDate() + 30);
        this.clientResource.get_events(from.toISOString(), to.toISOString()).subscribe(function (results) {
            _this.total_appointments = results.length;
            _this.active_appointments = results.length;
            _this.appointments = results.reduce(function (res, event) {
                if (!res.hasOwnProperty(event.status)) {
                    res[event.status] = [];
                }
                if (event.status === 'CANCELED') {
                    _this.active_appointments--;
                }
                var dp = new DatePipe(navigator.language);
                var dtstart = dp.transform(event.start, 'yyyMMddTHHmmss');
                var dtend = dp.transform(event.end, 'yyyyMMddTHHmmss');
                // const dtstamp = (new Date(event.start)).toISOString();
                var descr = event.appointment_type.description.replace('\n', '\\n');
                var vcard = ["BEGIN:VCALENDAR",
                    "PRODID:-//ZTimeSlot.guru//TimeSlot Guru Event 1.0//EN",
                    "VERSION:2.0",
                    "CALSCALE:GREGORIAN",
                    "BEGIN:VEVENT",
                    "DTSTAMP:" + dtstart,
                    "UID:" + event.id,
                    "SUMMARY:" + event.appointment_type.name,
                    "DTSTART:" + dtstart,
                    "DTEND:" + dtend,
                    "DESCRIPTION: " + descr,
                    "URL:" + location.href,
                    "STATUS:" + event.status,
                    // `SEQUENCE:3`,
                    "BEGIN:VALARM",
                    "TRIGGER:-PT24H",
                    "DESCRIPTION: 1 day until your appointment " + event.appointment_type.name,
                    "ACTION:DISPLAY",
                    "END:VALARM",
                    "BEGIN:VALARM",
                    "TRIGGER:-PT60M",
                    "DESCRIPTION: 1 hour until your appointment " + event.appointment_type.name,
                    "ACTION:DISPLAY",
                    "END:VALARM",
                    "END:VEVENT",
                    "END:VCALENDAR"];
                event.ics = _this.sanitizer.bypassSecurityTrustResourceUrl('data:text/calendar;base64,' + btoa(vcard.join('\r\n')));
                // tslint:disable-next-line:max-line-length
                event.google = encodeURI("https://www.google.com/calendar/event?action=TEMPLATE&sprop=name:" + event.appointment_type.name + "&sprop=website:" + window.location.href + "&details=" + event.appointment_type.description + "&text=" + event.appointment_type.name + "&dates=" + dtstart + "/" + dtend);
                var h = Math.floor(event.appointment_type.duration / 60);
                var m = event.appointment_type.duration % 60;
                var duration = String(h < 10 ? '0' + String(h) : h) + String(m < 10 ? '0' + String(m) : m);
                // tslint:disable-next-line:max-line-length
                event.yahoo = encodeURI("https://calendar.yahoo.com/?v=60&VIEW=d&TYPE=20&TITLE=" + event.appointment_type.name + "&ST=" + dtstart + "&DUR=" + duration + "&URL=" + window.location.href + "&DESC=" + event.appointment_type.description);
                res[event.status].push(event);
                return res;
            }, {});
        });
    };
    MainComponent.prototype.loadCalendars = function () {
        var _this = this;
        this.loading_calendars = true;
        this.calResource.get().subscribe(function (results) {
            _this.calendars = results.map(function (c) {
                c._appointmentTypes = c.appointment_types.reduce(function (types, t) {
                    types.push(t.name);
                    return types;
                }, []);
                // if (this.whitelabel.appointment_types) {
                //   this.whitelabel.appointment_types.forEach(a => {
                //     c._appointmentTypes.push(a.name);
                //   });
                // }
                return c;
            });
            _this.loading_calendars = false;
            if (_this.calendars.length === 1) {
                _this.searchForm.controls['calendar'].setValue(_this.calendars[0].id);
            }
            // @ts-ignore
            if (_this.appointmentTypesLength === 1) {
                _this.searchForm.controls['type'].setValue(_this.groupedAppointmentTypes()[Object.keys(_this.groupedAppointmentTypes())[0]]);
            }
        });
        if (this.whitelabel) {
            if (this.whitelabel.ask_email) {
                this.bookingForm.addControl('email', this.fb.control(sessionStorage.getItem('email'), Validators.compose([Validators.required, Validators.email])));
                this.bookingForm.registerControl('email', this.fb.control(sessionStorage.getItem('email'), Validators.compose([Validators.required, Validators.email])));
                this.filteredEmails = this.bookingForm.controls['email'].valueChanges
                    .pipe(startWith(''), map(function (value) { return _this._filterEmail(value); }));
            }
            if (this.whitelabel.ask_home_address) {
                if (sessionStorage.getItem('currentAppointmentUser')) {
                    this.clientResource.get_data({ client: sessionStorage.getItem('currentAppointmentUser') }).subscribe(function (data) {
                        _this.addresses = data['addresses'];
                        _this.emails = data['emails'];
                    }, function (error) {
                        console.warn(error);
                    });
                }
                this.searchForm.addControl('address_id', this.fb.control(sessionStorage.getItem('address_id'), Validators.required));
                this.searchForm.registerControl('address_id', this.fb.control(sessionStorage.getItem('address_id'), Validators.required));
            }
        }
    };
    MainComponent.prototype._filterEmail = function (value) {
        var filterValue = value.toLowerCase();
        return this.emails.filter(function (option) { return option.toLowerCase().includes(filterValue); }).sort(function (a, b) { return a > b ? 1 : (a < b ? -1 : 0); });
    };
    MainComponent.prototype._filterGroup = function (value) {
        if (value && typeof value === 'string') {
            // @ts-ignore
            return this.appointmentTypes
                .map(function (group) { return ({ name: group['name'], items: _filter(group['items'], value) }); })
                .filter(function (group) { return group.items.length > 0; });
        }
        // @ts-ignore
        return this.appointmentTypes;
    };
    return MainComponent;
}());
export { MainComponent };
