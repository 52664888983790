import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {GlobalSettings} from '../_providers';
import {AlertService, AppointmentResource, CalendarsResource, ClientResource, Settings} from '../_services';
import {FormBuilder} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import {DialogService} from 'ng2-bootstrap-modal';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-event',
  template: `
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
      <div class="container">
        <a class="navbar-brand" href="/"><img src="{{whitelabel.logo}}" alt="{{whitelabel.title}}" class="pull-left"></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive"
                aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarResponsive">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <a class="nav-link" href="/" routerLink="/">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/logout" routerLink="/logout"><i class="fas fa-door-open"></i> Log-out</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div class="content-wrapper">
      <div class="container">
        <h2 class="mb-3">{{greeting}}</h2>

        <alert></alert>

        <div *ngIf="event">
          <h3 *ngIf="event.status!='CANCELED'">You got an appointment</h3>
          <h3 *ngIf="event.status=='CANCELED'">You had an appointment</h3>
          <h4>{{event.start | date : "medium"}}</h4>
          <span class="badge" [ngClass]="{'badge-success':event.status=='CONFIRMED', 'badge-warning':event.status!='CONFIRMED'}">{{event.status}}</span>

          <p class="mt-sm-3">
            <span *ngIf="event.status!='CANCELED'">Your appointment is for </span>
            <span *ngIf="event.status=='CANCELED'">Your canceled appointment was for </span>
            <b>{{event.appointment_type.name}}</b><br/>
            <em>{{event.appointment_type.description}}</em>
          </p>

          <p *ngIf="event.status=='CANCELED'">
            To make a new appointment, <a href="/" routerLink="/"><b>click here</b></a>.
          </p>

          <p>
            <button type="button" *ngIf="event.allowed_operations.indexOf('confirm') >= 0"
                    class="mr-1 btn btn-info"
                    (click)="confirmAppointment()">Confirm this appointment
            </button>
            <button type="button" *ngIf="event.allowed_operations.indexOf('cancel') >= 0"
                    class="btn btn-danger" (click)="cancelAppointment()">Cancel this appointment
            </button>
            <span class="btn-group" *ngIf="event.status!='CANCELED'">
          <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Add to Calendar
          </button>
          <ul class="dropdown-menu" >
            <li><a [href]="event.google" target="_blank">Google Calendar</a></li>
            <li><a [href]="event.ics" download>iCal</a></li>
            <li><a [href]="event.ics" download>Outlook</a></li>
            <li><a [href]="event.yahoo" target="_blank">Yahoo! Calendar</a></li>
          </ul>
        </span>
          </p>

        </div>

        <div class="row" *ngIf="whitelabel.page_footer" >
          <div class="col-12 mt-lg" [innerHTML]="page_footer()"></div>
        </div>

      </div>
    </div>

  `,
})

export class EventComponent implements OnInit, AfterViewInit {
  whitelabel: Settings;
  client: any;
  calendar: any;
  event: any;
  dialogs: any;
  greeting: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private globalSettings: GlobalSettings,
    private alertService: AlertService,
    private calResource: CalendarsResource,
    private appointmentsResource: AppointmentResource,
    private clientResource: ClientResource,
    private builder: FormBuilder,
    private sanitizer: DomSanitizer,
    private dialogService: DialogService,
  ) {
    this.dialogs = {};
    this.whitelabel = globalSettings.whitelabel;
  }

  ngOnInit() {
    this.whitelabel = this.globalSettings.whitelabel;

    this.globalSettings.whitelabel$.subscribe((val: Settings) => {
      this.whitelabel = val;
      if (val.hasOwnProperty('client')) {
        this.client = val.client;
        this.route.params.subscribe((params: Params) => {
          sessionStorage.setItem('calendar', params['calendarId']);
          sessionStorage.setItem('name', this.client.name);
          sessionStorage.setItem('msisdn', this.client.msisdn);
          this.globalSettings.setUser(this.client.id);
          this.greeting = `Hello ${this.client.name}, welcome to ${this.whitelabel.title}`;
          // sessionStorage.setItem('message', JSON.stringify({type: 'success', content: `Hello ${data.name}`}));
          // router.navigateByUrl('/');

          this.appointmentsResource.get(params['calendarId'], params['eventId']).subscribe(event => {
            this.event = event;
            sessionStorage.setItem('event', params['eventId']);
            const dp = new DatePipe(navigator.language);
            const dtstart = dp.transform((new Date(event.start)), 'yyyMMddTHHmmss');
            const dtend = dp.transform((new Date(event.end)), 'yyyyMMddTHHmmss');
            // const dtstamp = (new Date(event.start)).toISOString();
            const descr = event.appointment_type.description.replace('\n', '\\n');
            const vcard = [`BEGIN:VCALENDAR`,
              `PRODID:-//ZTimeSlot.guru//TimeSlot Guru Event 1.0//EN`,
              `VERSION:2.0`,
              `CALSCALE:GREGORIAN`,
              `BEGIN:VEVENT`,
              `DTSTAMP:${dtstart}`,
              `UID:${event.id}`,
              `SUMMARY:${event.appointment_type.name}`,
              `DTSTART:${dtstart}`,
              `DTEND:${dtend}`,
              `DESCRIPTION: ${descr}`,
              `URL:${location.href}`,
              `STATUS:${event.status}`,
              // `SEQUENCE:3`,
              `BEGIN:VALARM`,
              `TRIGGER:-PT24H`,
              `DESCRIPTION: 1 day until your appointment ${event.appointment_type.name}`,
              `ACTION:DISPLAY`,
              `END:VALARM`,
              `BEGIN:VALARM`,
              `TRIGGER:-PT60M`,
              `DESCRIPTION: 1 hour until your appointment ${event.appointment_type.name}`,
              `ACTION:DISPLAY`,
              `END:VALARM`,
              `END:VEVENT`,
              `END:VCALENDAR`];

            event.ics = this.sanitizer.bypassSecurityTrustResourceUrl('data:text/calendar;base64,' + btoa(vcard.join('\r\n')));

            event.google = encodeURI(`https://www.google.com/calendar/event?action=TEMPLATE&sprop=name:${event.appointment_type.name}&sprop=website:${window.location.href}&details=${event.appointment_type.description}&text=${event.appointment_type.name}&dates=${dtstart}/${dtend}`);

            const h = Math.floor( event.appointment_type.duration / 60);
            const m = event.appointment_type.duration % 60;
            const duration = String(h < 10 ? '0' + String(h) : h) + String(m < 10 ? '0' + String(m) : m);
            event.yahoo = encodeURI(`https://calendar.yahoo.com/?v=60&VIEW=d&TYPE=20&TITLE=${event.appointment_type.name}&ST=${dtstart}&DUR=${duration}&URL=${window.location.href}&DESC=${event.appointment_type.description}`);
          }, error => {
            this.alertService.error('Event was not found!');
            this.router.navigateByUrl('/');
          });
        });
      } else {
        this.alertService.error('Sorry, we were unable to identify you!');
        this.router.navigateByUrl('/login');
      }
    });
  }

  ngAfterViewInit(): void {
  }

  confirmAppointment(): void {
    this.appointmentsResource.confirm(this.event.calendar_id, this.event.id).subscribe(response => {
      this.alertService.success('Your appointment has been confirmed!');
      this.ngOnInit();
    }, error => {
      this.alertService.error(error.error);
    });
  }

  cancelAppointment(): void {
    if (confirm('Are you sure you want to cancel this appointment?\nYou may not be able to re-book the same time slot.')) {
      this.appointmentsResource.cancel(this.event.calendar_id, this.event.id).subscribe(response => {
        this.alertService.info('Your appointment has been cancelled!');
        window.location.href = '/';
      }, error => {
        this.alertService.error(error.error);
      });
    }
  }

  page_footer(): any {
    return this.sanitizer.bypassSecurityTrustHtml(this.whitelabel.page_footer);
  }

}
