
    <div class="modal-dialog" *ngIf="!whitelabel">
      <app-loading></app-loading>
      Page loading, please wait!
    </div>
    <link *ngIf="whitelabel && whitelabel.css" [href]="whitelabel.css" rel="stylesheet" id="whitelabelCss" />

    <div class="content">
      <div class="container">
        <router-outlet></router-outlet>
      </div>
    </div>
  